* {
  margin: 0;
}

#aCC5 {position:absolute; z-index:100; left: 50%; top: 0;}
#mc5 {position:absolute; z-index:150; left: -500px; top: 0px;}

#mc5 h1, #mc5 h2, #mc5 h3 {
  font-weight: 400;
}

.comp{
  position: absolute;
}


